import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'gatsby-theme-husky/src/layout/Layout';
import { SimplepageTypes } from '@shared/types/umbraco/content/simplepage';
import FaqSection from 'gatsby-theme-husky/src/components/FaqSection';
import SimpleBanner from 'components/SimpleBanner';
import BodyRenderer from 'gatsby-theme-husky/src/common/BodyRenderer';
import WideBanner from 'gatsby-theme-husky/src/components/WideBanner';
import SlimBanner from 'gatsby-theme-husky/src/components/SlimBanner';
import InfoBanner from 'components/InfoBanner';
import TextSection from 'components/TextSection';
import ProductListingBanner from 'components/ProductListingBanner';
import RelatedArticles from 'components/RelatedArticles';
import DescriptionGridLink from 'common/DescriptionGridLink';
import SignUp from 'components/SignUp';

const elements = {
  'Slim banner': ({ properties }, keyId) => <SlimBanner key={keyId} {...properties} />,
  'FAQ Section': ({ properties }, keyId) => <FaqSection key={keyId} {...properties} />,
  'Info Banner Wide': ({ properties }, keyId) => <WideBanner key={keyId} {...properties} />,
  'Info Banner': ({ properties }, keyId) => <InfoBanner key={keyId} {...properties} />,
  'Text Section': ({ properties }, keyId) => <TextSection key={keyId} {...properties} />,
  Partners: ({ properties }, keyId) => <DescriptionGridLink key={keyId} {...properties} />,
  'Signup Placeholder': ({ properties }, keyId) => <SignUp key={keyId} {...properties} />,
  'Product Listing Banner': ({ properties }, keyId) => (
    <ProductListingBanner key={keyId} {...properties} />
  ),
  'Related Articles': ({ properties }, keyId) => <RelatedArticles key={keyId} {...properties} />,
};

const SimplePage: FC<SimplepageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl, relatedArticlesLinks },
  data: {
    page: {
      nodes: [
        {
          banner,
          defaultCompositions,
          body,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          alternateUrls,
        },
      ],
    },
    relatedProducts,
    relatedArticles,
  },
}) => {
  const { singupFormBaner, waiSettings } = defaultCompositions;

  return (
    <Layout
      {...{
        ...defaultCompositions,
        seoMetaKeywords,
        seoMetaTitle,
        seoNoIndex,
        seoMetaDescription,
        seoExternalHreflangs,
        skipText: waiSettings?.skipText,
        link,
        searchUrl,
        alternateUrls,
        imageUrl: banner[0].properties?.image?.fallbackUrl,
        imageAlt: banner[0].properties?.title,
      }}
    >
      {banner?.length ? <SimpleBanner {...{ ...banner[0].properties, breadCrumbs }} /> : null}

      <BodyRenderer
        bodyData={body}
        bodyStructure={elements}
        bodyItemProps={{
          ...{ singupFormBaner, relatedProducts, relatedArticles, relatedArticlesLinks },
        }}
      />
    </Layout>
  );
};
export const query = graphql`
  query allSimpleQuery(
    $link: String = ""
    $relatedProductLinks: [String]
    $relatedArticlesLinks: [String]
  ) {
    relatedProducts: allProduct(filter: { link: { in: $relatedProductLinks } }) {
      nodes {
        link
        ean
        useRedirects
        tags {
          isCategory
          isFamily
          color {
            label
          }
          isLabel
          title
        }
        title
        cardDescription
        shortDescription
        images {
          properties {
            imageAlt
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    relatedArticles: allArticle(filter: { link: { in: $relatedArticlesLinks } }) {
      nodes {
        link
        tags {
          isCategory
          isLabel
          title
        }
        title
        lang
        date
        dateSufix
        image {
          properties {
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageAlt
          }
        }
      }
    }
    page: allSimple(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        alternateUrls {
          lang
          url
          path
        }
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          header {
            ...FragmentHeader
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          waiSettings {
            skipText
          }
          warning {
            ...FragmentWarning
          }
        }
        banner {
          ...FragmentSimpleBanner
        }
        body {
          ... on TSlimbanner {
            ...FragmentSlimBanner
          }
          ... on TFAQSection {
            ...FragmentFaqSection
          }
          ... on TInfoBanner {
            ...FragmentInfoBanner
          }
          ... on TInfoBannerWide {
            ...FragmentWideBanner
          }
          ... on TTextSection {
            ...FragmentTextSection
          }
          ... on TPartners {
            ...FragmentPartners
          }
          ... on TSignupPlaceholder {
            ...FragmentSignUp
          }
          ... on TProductListingBanner {
            ...FragmentProductListingBanner
          }
          ... on TRelatedArticles {
            ...FragmentRelatedArticles
          }
        }
      }
    }
  }
`;

export default SimplePage;
